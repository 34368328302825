import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"


const CURRENT_PROJECTS_LIST = [
  {
    name: "Record Bets",
    href: "https://recordbets.app",
    skills: [
      "react",
      "node",
      "tailwind",
      "graphql",
      "typescript",
      "devops",
    ],
    description: `Easily manage, track, and share sports handicapping cards with subscribers.`,
  },
  {
    name: "Chrona",
    href: "https://chrona.io",
    skills: [
      "react",
      "node.js",
      "typescript",
      "open source",
      "api",
      "aws",
      "devops",
    ],
    description: `Open-sourced API for saving and sharing your bookmarks`,
  },
  {
    name: "This Website",
    href: "https://github.com/jmfurlott/jmfurlott",
    skills: ["react", "gatsby"],
    description: `Uses React by way of Gatsby to create a static site with blog entries as
    Markdown. Deployed using AWS Amplify!`,
  },
]

const FLOSS_PROJECTS_LIST = [
  {
    name: "node-typescript-boilerplate",
    href: "https://github.com/jmfurlott/node-typescript-boilerplate",
    skills: ["node", "typescript", "express", "jest"],
    description: `A simple starter project with node and typescript with
    express, jest, eslint, and prettier setup.`,
  },
  {
    name: "react-isomorphic-boilerplate",
    skills: ["react"],
    href: "https://github.com/jmfurlott/react-isomorphic-boilerplate",
    description: `Deprecated nowadays but was an early-react-days project to
    make it easier to getting started with a React project. create-react-app and
    other ways of managing server side rendering have since ended the need for
    this project (and thankfully).`,
  },
]

const PAST_PROJECTS_LIST = [
  {
    name: "Ravti",
    href: "https://ravti.com",
    skills: ["react", "node.js", "typescript"],
    description: `A platform to help property managers procure, repair, and maintain HVAC
      assets at their properties. Designed and engineered the Node.js backend,
      React frontend, development operations to AWS, and mobile react-native
      applications. Additionally responsible for the maintenance of the
      corporate landing marketing page, and internal/external design materials.
      Also, responsibile for a React Native application that allows mobile
      access to the main Ravti web app. Includes navigation, access to the
      website, and a QR Code scanner that scan the codes from their HVAC
      inventory. Handled from beginning to Apple's app store production, and
      includes 90%+ transferable code to the same app on the Android platform.`,
  },
  {
    name: "NYC Subway App",
    skills: ["react", "swift", "react-native"],
    href: "https://itunes.apple.com/us/app/ny-subway/id1252863434?ls=1&mt=8",
    description: `Originally started as a react-native project to experience the
      development and release lifecycle of working with react-native in the iOS
      app store. After successfully launching, ported the app to Swift proper to
      release again.  A very fun and constructive learning experience.`,
  },
  // {
  //   name: "react-styleguide",
  //   skills: ["react"],
  //   href: "https://github.com/jmfurlott/react-styleguide",
  //   description: `A React component that takes in other components and organizes
  //   them into an easy-to-use styleguide. This project has been deprecated
  //   as we have storybook.js now!`,
  // },
  // {
  //   name: "react-table-filter",
  //   skills: ["react"],
  //   href: "https://github.com/jmfurlott/react-table-filter",
  //   description: "Just an example for one of my blogs",
  // },
  // {
  //   name: "react-viewport",
  //   skills: ["react"],
  //   href: "https://github.com/jmfurlott/react-viewport",
  //   description: `Build React components with specified view height and width
  //   units. Supports fallback values for browsers that do not support vh or vw.`,
  // },
]

class ProjectsIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={`Projects`}>
        <SEO
          title="Projects"
          keywords={[`projects`, `resume`, `jmfurlott`, `javascript`, `react`]}
        />
        <h2>Projects</h2>
        {CURRENT_PROJECTS_LIST.map(p => (
          <React.Fragment>
            <h3 style={{ marginBottom: rhythm(1 / 4) }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#DFAF8F" }}
                href={p.href}
              >
                {p.name}
              </a>
            </h3>
            <h6 style={{ marginBottom: rhythm(1 / 4), color: "#93E0E3" }}>
              {p.skills.join(", ")}
            </h6>
            <p>{p.description}</p>
          </React.Fragment>
        ))}
        <hr style={{ margin: "2rem 0", background: "white", padding: "1px" }} />
        <h2>Past Projects</h2>
        {PAST_PROJECTS_LIST.map(p => (
            <React.Fragment>
            <h3 style={{ marginBottom: rhythm(1 / 4) }}>
            <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#DFAF8F" }}
          href={p.href}
            >
            {p.name}
          </a>
            </h3>
            <h6 style={{ marginBottom: rhythm(1 / 4), color: "#93E0E3" }}>
            {p.skills.join(", ")}
          </h6>
            <p>{p.description}</p>
            </React.Fragment>
        ))}
        <hr style={{ margin: "2rem 0", background: "white", padding: "1px" }} />
        <h2>Open Source Projects</h2>
        {FLOSS_PROJECTS_LIST.map(p => (
            <React.Fragment>
            <h3 style={{ marginBottom: rhythm(1 / 4) }}>
            <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#DFAF8F" }}
          href={p.href}
            >
            {p.name}
          </a>
            </h3>
            <h6 style={{ marginBottom: rhythm(1 / 4), color: "#93E0E3" }}>
            {p.skills.join(", ")}
          </h6>
            <p>{p.description}</p>
            </React.Fragment>
        ))}
      </Layout>
    )
  }
}

export default ProjectsIndex
